














































import { VForm } from "@/plugins/vuetify";
import { IResponse } from "@/store/response.interface";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component
export default class Register extends Vue {
  private password: string = '';
  private loading: boolean = false;
  private showPassword: boolean = false;
  private errorMessage?: string = '';

  @Auth.Action
  private reset!: (data: any) => Promise<IResponse>;

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async handleReset() {
    this.loading = true;
    const response = await this.reset({
      password: this.password,
      token: this.$route.params.token
    });
    this.$router.push('/login');
    this.loading = false;
  }
}
